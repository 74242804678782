<template>
  <div class="page-wrapper">
    <div class="container-fluid">
      <div class="d-block d-md-flex justify-content-between">
        <ul id="breadcrumb" class="breadcrumb ProximaNovaRegular mx-4">
           <li>
            <router-link to="/saved-plans">
              <span>Saved Model</span>
            </router-link>
          </li>
          <li>
            <router-link to="/saved/model/results">
              <span>Model</span>
            </router-link>
          </li>
          <li>
            <router-link to="/saved/model/results/stats">
              <span>Sale Statistics</span>
            </router-link>
          </li>
          <li class="ProximaNovaBold" v-if="activeTab === 'Attribution'">
            Attribution
          </li>
          <li class="ProximaNovaBold" v-if="activeTab === 'Future Planning'">
            Future Planning
          </li>
          <li class="ProximaNovaBold" v-if="activeTab === 'ROI Summary'">
            ROI Summary
          </li>
        </ul>
        <div v-if="activeTab !== 'Future Planning'">
          <div class="" style="height: 40%">
            <div class="w-100 p-4 pr-5">
              <w-white-dropdown
                :options="marketDropdown"
                :selectedOption="selectedMarket"
                :labelText="'Model'"
                class="mr-3"
                :placeHolder="selectedMarket.text"
                @input="selectMarket($event)"
              ></w-white-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mt-5">
      <w-tab :tabName="activeTab" :tabs="tabs" @activeTab="changeTab($event)">
      </w-tab>
      <div v-if="activeTab === 'Attribution'">
        <div class="mt-5" :class="breakDown ? 'card' : ''">
          <b-row class="no-gutters">
            <b-col lg="4" :class="breakDown ? '' : 'card'">
              <div>
                <pie-chart
                  title="Overall"
                  :colors="pieChartColor"
                  :series="overallSeries"
                  :showBreakdown="true"
                  :breakdown="breakDown"
                  @getBreakdown="getBreakdown"
                />
              </div>
            </b-col>
            <b-col lg="8" v-if="breakDown" class="breakdownable-card">
              <multiple-donut-chart
                :series1="externalData"
                :series2="internalData"
                :title="multipleTitle"
              />
            </b-col>
          </b-row>
        </div>
        <!--<div class="card mt-5 p-5">
          <b-container fluid>
            <Digital
              :data="digitalExpandData"
              :stripData="digitalData"
              @getDataBreakdown="getDigitalBreakdown"
            />
          </b-container>
        </div>
        <div class="card mt-5 p-5">
          <b-container fluid>
            <Content
              :barData="contentExpandData"
              :stripData="contentData"
              @getDataBreakdown="getContentBreakdown"
            />
          </b-container>
        </div>-->
        <div
          class="card mt-5 p-5"
          v-for="(data, index) in attributionData"
          :key="index"
        >
          <b-container fluid>
            <Content
              :title="data.title"
              ref="solutionModal"
              :barData="data.expandData"
              :stripData="data.stripData"
              :breakeDownShow="data.breakDown"
              :showLegend="true"
              @getDataBreakdown="getBreakdownData($event, index)"
            />
          </b-container>
        </div>
      </div>
      <!--<w-summary v-if="activeTab === 'ROI Summary'"></w-summary>
      <w-future-planning
        v-if="activeTab === 'Future Planning'"
      ></w-future-planning>-->
    </div>
  </div>
</template>
<script>
import Tab from "@/components/Solutions/Tab.vue";
import PieChart from "@/components/Chart/PieChart.vue";
// import Digital from "@/components/Solutions/Digital.vue";
import Content from "@/components/Solutions/Content.vue";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
import MultipleDonutChart from "@/components/Chart/MultipleDonutChart.vue";
// import ROISummary from "./Summary.vue";
// import FuturePlanning from "./FuturePlanning.vue";
import { DigitalPoemServices } from "@/services/DigitalPoemServices.js";
const digitalPoemService = new DigitalPoemServices();
import { AIDigitalPoem } from "@/services/AIDigitalPoemServices";
const aiDigitalPoem = new AIDigitalPoem();
import { abbreviateNumber } from "js-abbreviation-number";

export default {
  name: "Insights",
  components: {
    // "w-summary": ROISummary,
    "w-tab": Tab,
    "w-white-dropdown": WhiteDropdown,
    // "w-future-planning": FuturePlanning,
    PieChart,
    // Digital,
    Content,
    MultipleDonutChart,
  },
  data() {
    return {
      classess: [
        "blue-pastel",
        "green-pastel",
        "pink-pastel",
        "red-pastel",
        "yellow-pastel",
        "purple-pastel",
        "green-pastel",
      ],
      colorList: [
        "#85edff",
        "#b5a9ff",
        "#DDDF00",
        "#24CBE5",
        "#64E572",
        "#FF9655",
        "#FFF263",
        "#6AF9C4",
        "#85edff",
        "#b5a9ff",
        "#DDDF00",
        "#24CBE5",
        "#64E572",
        "#FF9655",
        "#FFF263",
        "#6AF9C4",
      ],
      attributionData: [
        {
          title: "",
          stripData: [],
          expandData: {
            yAxis: [
              {
                lineWidth: 1,
                title: {
                  text: null,
                },
              },
              {
                min: 0,
                max: 100,
                title: {
                  text: "",
                  align: "high",
                },
                gridLineDashStyle: "shortdash",
                labels: {
                  overflow: "justify",
                  style: {
                    color: "#8394b4",
                  },
                  formatter: function() {
                    var label = this.axis.defaultLabelFormatter.call(this);
                    return label + "%";
                  },
                },
                opposite: true,
                tickInterval: 25,
              },
            ],
            tooltip: {
              valueSuffix: "",
            },
            legend: {
              layout: "horizontal",
              align: "left",
              verticalAlign: "bottom",
              itemStyle: {
                color: "#8394b4",
              },
            },
            credits: {
              enabled: false,
            },
            colors: [
              "#ffd740",
              "#18ffff",
              "#69f0ae",
              "#ff9100",
              "#ff5252",
              "#1e88e5",
              "#5e35b1",
              "#00897b",
            ],
            title: {
              text: "",
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: false,
                },
              },
              showInLegend: true,
              series: {
                borderRadiusTopLeft: 25,
                borderRadiusTopRight: 25,
              },
            },
            series: [],
            xAxis: {
              categories: [],
              title: {
                text: null,
              },
              labels: {
                enabled: false,
                style: {
                  color: "#8394b4",
                  fontSize: "16px",
                  fontFamily: "ProximaNovaRegular",
                },
              },
            },
          },
          allExpandData: [],
        },
      ],
      breakDown: false,
      colors: [
        "#ffd740",
        "#18ffff",
        "#69f0ae",
        "#ff9100",
        "#ff5252",
        "#1e88e5",
        "#5e35b1",
        "#00897b",
      ],
      pieChartColor: ["#ff4081", "#64ffda"],
      contentBreakDown: false,
      activeTab: "Attribution",
      activeFrom: 1,
      selectedMarket: "",
      tabs: [
        {
          category: "Attribution",
        },
        // {
        //   category: "Future Planning",
        // },
        // {
        //   category: "ROI Summary",
        // },
      ],
      marketDropdown: [],
      overallSeries: [
        {
          name: "Overall",
          colorByPoint: true,
          data: [],
        },
      ],
      digitalData: [],
      contentData: [],
      externalData: [],
      internalData: [],
      contentExpandData: {
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#8394b4",
              },
              formatter: function() {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#ff5252",
          "#1e88e5",
          "#5e35b1",
          "#00897b",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
      },
      digitalExpandData: [],
      multipleTitle: [],
    };
  },

  created() {
    this.selectedMarket = JSON.parse(sessionStorage.getItem("activeSavedModalName"));
    this.marketDropdown = [];
    this.marketDropdown = JSON.parse(sessionStorage.getItem("SavedmodalList"));
    // sessionStorage.setItem('runId','202110-0809-0503-8fef-4_ykbfqzxtqdiclezqnauk')
    this.getOverallChartData();
    this.getAllChartData();
    // this.getExternalData();
    // sessionStorage.removeItem("edited");
    // this.getFirstLevelData("digitalData", "digital");
    // this.getFirstLevelData("contentData", "content");
  },
  methods: {
    getBreakdownData(e, index) {
      this.attributionData[index].expandData.series = [];
      this.attributionData[index].breakDown = true;
      var expandDataObj = this.attributionData[index].allExpandData.find(
        (obj) => obj.title.toLowerCase() === e.label.toLowerCase()
      ).value;
      if (expandDataObj !== undefined) {
        console.log(expandDataObj);
        for (var i = 0; i < expandDataObj.length; i++) {
          console.log(expandDataObj[i]);
          this.attributionData[index].expandData.series.push({
            name: expandDataObj[i].name,
            data: [this.orgnizeNumber(expandDataObj[i].value * 100)],
            pointWidth: 16,
            yAxis: 1,
          });
        }
      }
    },
    getFirstLevelData(dataVariable, param) {
      var color = [];
      if (param === "content") {
        color = ["#fb8c00", "#ffb74d"];
      } else {
        color = [
          "#8e24aa",
          "#8e24aa",
          "#ab47bc",
          "#ce93d8",
          "#e1bee7",
          "#8e24aa",
          "#8e24aa",
          "#ab47bc",
          "#ce93d8",
          "#e1bee7",
        ];
      }
      digitalPoemService
        .getChartData(
          "sales causality-digitalpoem",
          "/b2c/in/",
          param,
          this.selectedMarket.id
        )
        .then((res) => {
          this[dataVariable] = [];
          for (var i = 0; i < res.length; i++) {
            this[dataVariable].push({
              label: res[i].name,
              value: res[i].value,
              backgroundColor: color[i],
            });
          }
        });
    },
    getExternalData() {
      digitalPoemService
        .getChartData(
          "sales causality-digitalpoem",
          "/b2c/in/",
          "external factors",
          this.selectedMarket.id
        )
        .then((res) => {
          this.externalData = [];
          for (var i = 0; i < res.length; i++) {
            this.externalData.push({
              name: res[i].name,
              y: res[i].value,
              z: 92.9,
              dataLabels: {
                formatter: function() {
                  return this.y > 1
                    ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                    : null;
                },
              },
              id: "id" + i,
            });
          }
        });
    },
    getOverallChartData() {
      aiDigitalPoem
        .getChartData(
          {
            uniqueId: sessionStorage.getItem("savedRunId"),
            model: this.selectedMarket.id,
          },
          // {
          //   uniqueId: "202110-0109-2456-e2fd-4_dwhetfmmmyiyjoznbwib",
          //   model: "dnnRegressor",
          // },
          "Attribution",
          "InternalExternal"
        )
        .then((res) => {
          this.overallSeries[0].data = [];
          for (var i = 0; i < res.response.levelOneData.length; i++) {
            if (i === 0) {
              this.overallSeries[0].data.push({
                name: res.response.levelOneData[i].name,
                y: this.orgnizeNumber(res.response.levelOneData[i].value * 100),
                sliced: true,
              });
            } else {
              this.overallSeries[0].data.push({
                name: res.response.levelOneData[i].name,
                y: this.orgnizeNumber(res.response.levelOneData[i].value * 100),
              });
            }
          }
          this.multipleTitle = [];
          for (i = 0; i < res.response.levelTwoData.length; i++) {
            if (res.response.levelTwoData[i].title === "External") {
              this.multipleTitle.push("External");
              this.externalData = [];
              for (
                var j = 0;
                j < res.response.levelTwoData[i].value.length;
                j++
              ) {
                this.externalData.push({
                  name: res.response.levelTwoData[i].value[j].name,
                  y: res.response.levelTwoData[i].value[j].value * 100,
                  z: 92.9,
                  dataLabels: {
                    formatter: function() {
                      return this.y > 1
                        ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                        : null;
                    },
                  },
                  id: "id" + j,
                });
              }
            } else if (res.response.levelTwoData[i].title === "Internal") {
              this.multipleTitle.push("Internal");
              this.internalData = [];
              for (j = 0; j < res.response.levelTwoData[i].value.length; j++) {
                this.internalData.push({
                  name: res.response.levelTwoData[i].value[j].name,
                  y: Math.round(
                    res.response.levelTwoData[i].value[j].value * 100
                  ),
                  z: 92.9,
                  dataLabels: {
                    formatter: function() {
                      return this.y > 1
                        ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                        : null;
                    },
                  },
                  id: "id" + j,
                });
              }
            }
          }
          this.breakDown = true;
        });
    },
    getAllChartData() {
      aiDigitalPoem
        .getChartData(
          {
            uniqueId: sessionStorage.getItem("savedRunId"),
            model: this.selectedMarket.id,
          },
          // {
          //   uniqueId: "202110-0109-2456-e2fd-4_dwhetfmmmyiyjoznbwib",
          //   model: "dnnRegressor",
          // },
          "Attribution",
          "DigitalMedia"
        )
        .then((res) => {
          this.attributionData = [];
          for (var i = 0; i < res.response.length; i++) {
            var stripData = [];
            for (var j = 0; j < res.response[i].levelOneData.length; j++) {
              stripData.push({
                label: res.response[i].levelOneData[j].name,
                value: this.orgnizeNumber(
                  res.response[i].levelOneData[j].value * 100
                ),
                backgroundColor: this.colorList[j],
                symbol: "%",
              });
              if (j === res.response[i].levelOneData.length - 1) {
                this.attributionData.push({
                  title: res.response[i].title,
                  stripData: stripData,
                  expandData: {
                    yAxis: [
                      {
                        lineWidth: 1,
                        title: {
                          text: null,
                        },
                      },
                      {
                        min: 0,
                        max: 100,
                        title: {
                          text: "",
                          align: "high",
                        },
                        gridLineDashStyle: "shortdash",
                        labels: {
                          overflow: "justify",
                          style: {
                            color: "#8394b4",
                          },
                          formatter: function() {
                            var label = this.axis.defaultLabelFormatter.call(
                              this
                            );
                            return label + "%";
                          },
                        },
                        opposite: true,
                        tickInterval: 25,
                      },
                    ],
                    tooltip: {
                      valueSuffix: "",
                    },
                    legend: {
                      layout: "horizontal",
                      align: "left",
                      verticalAlign: "bottom",
                      itemStyle: {
                        color: "#8394b4",
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    colors: [
                      "#ffd740",
                      "#18ffff",
                      "#69f0ae",
                      "#ff9100",
                      "#ff5252",
                      "#1e88e5",
                      "#5e35b1",
                      "#00897b",
                    ],
                    title: {
                      text: "",
                    },
                    plotOptions: {
                      bar: {
                        dataLabels: {
                          enabled: false,
                        },
                      },
                      showInLegend: true,
                      series: {
                        borderRadiusTopLeft: 25,
                        borderRadiusTopRight: 25,
                      },
                    },
                    series: [],
                    xAxis: {
                      categories: [],
                      title: {
                        text: null,
                      },
                      labels: {
                        enabled: false,
                        style: {
                          color: "#8394b4",
                          fontSize: "16px",
                          fontFamily: "ProximaNovaRegular",
                        },
                      },
                    },
                  },
                  breakDown: false,
                  allExpandData: res.response[i].levelTwoData,
                });
              }
            }
          }
        });
    },
    changeTab(tabName) {
      console.log(tabName);
      this.activeTab = tabName;
    },
    getContentBreakdown(data) {
      digitalPoemService
        .getChartData(
          "sales causality-digitalpoem",
          "/b2c/in/",
          data.label.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.contentExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.contentExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
        });
    },
    getDigitalBreakdown(data) {
      digitalPoemService
        .getChartData(
          "sales causality-digitalpoem",
          "/b2c/in/",
          data.label.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.digitalExpandData = [];
          var count = 0;
          for (var i = 0; i < res.length; i++) {
            this.digitalExpandData.push({
              name: res[i].name,
              pointWidth: 20,
              data: [
                {
                  x: count,
                  x2: count + res[i].value,
                  y: i + 1,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: false,
              },
            });
            count += res[i].value;
          }
        });
    },
    getBreakdown(newVal) {
      this.breakDown = newVal;
    },
    getModalSummary() {
      aiDigitalPoem
        .getAllModals({ uniqueId: sessionStorage.getItem("savedRunId"),model:"" })
        .then((res) => {
          var salseStatics = [];
          for (var i = 0; i < res.response.values.length; i++) {
            if (this.selectedMarket.id === res.response.values[i].title) {
              for (var j = 0; j < res.response.values[i].statics.length; j++) {
                salseStatics.push({
                  name: res.response.values[i].statics[j].name,
                  value: this.orgnizeNumber(
                    res.response.values[i].statics[j].value
                  ),
                  className: this.classess[j],
                });
              }
            }
          }
          sessionStorage.setItem("selectedSavedPlan", JSON.stringify(salseStatics));
          this.getOverallChartData();
          this.getAllChartData();
        });
    },
    selectMarket(e) {
      if (e.id === this.selectedMarket.id) {
        return;
      }
      this.getModalSummary();
      sessionStorage.setItem("activeSavedModalName", JSON.stringify(e));
      this.selectedMarket = e;
      this.breakDown = false;
    },
  },
  computed: {
    orgnizeNumber() {
      return (data) => {
        if (data > 999) {
          return abbreviateNumber(data);
        } else {
          return Math.round(data);
        }
      };
    },
  },
};
</script>

<style scoped>
.page-wrapper {
  margin-top: 71px;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
}

.card-heading {
  font-size: 18px;
  font-weight: bold;
}

.breakdownable-card {
  border-left: 2px solid #d4dae4;
  position: relative;
}

.breakdownable-card::before {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  background: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: -17px;
  z-index: 9999;
  clip-path: polygon(50% 0, 0 50%, 50% 100%);
}

.breakdownable-card::after {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  background: #d4dae4;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
  z-index: 999;
  clip-path: polygon(50% 0, 0 50%, 50% 100%);
}

.item {
  margin-top: 20px;
}

.title {
  font-family: ProximaNovaRegular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9aafd4;
  margin-bottom: 5px;
}

::v-deep .progress-bar {
  text-align: end;
  padding-right: 6px;
}
</style>
